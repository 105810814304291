import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ visible, onClose }) => {

  return (
    <div
      className={`${
        visible
          ? "fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
          : "hidden"
      }`}
    >
      <div
        className={`bg-white p-5 mx-7 lg:mx-20 rounded h-[90%] lg:h-4/5 block relative`}
      >
        <div className="absolute -top-5 -right-5">
          <button
            onClick={onClose}
            className="border-[3px] border-gold text-white bg-black
            rounded-lg font-medium text-base p-2 mt-1"
          >
            <AiOutlineClose />
          </button>
        </div>
        <div className="h-full overflow-y-scroll">
          <p className="text-justify pr-3">
            <b>Your Responsibilities:</b>
            <br />
            <br /> Your Accounts: You are responsible for all activities that
            occur under your account, regardless of whether the activities are
            authorized by you or undertaken by you, your employees or a third
            party (including your contractors, agents or other End Users), and
            we and our affiliates are not responsible for unauthorized access to
            your account, including any access that occurred as a result of
            fraud, phishing, or other criminal activity perpetrated by third
            parties.
            <br />
            <br /> Your Use: You are responsible for all activities that occur
            through your use of those Offerings that do not require an account,
            except to the extent caused by our breach of this Agreement,
            regardless of whether the activities are authorized by you or
            undertaken by you, your employees or a third party (including your
            contractors, agents or other End Users). We and our affiliates are
            not responsible for unauthorized access that may occur during your
            use of the Offerings, including any access that occurred as a result
            of fraud, phishing, or other criminal activity perpetrated by third
            parties. You will ensure that your use of the Offerings does not
            violate any applicable law.
            <br />
            <br /> Your Security and Backup: You are solely responsible for
            properly configuring and using the Offerings and otherwise taking
            appropriate action to secure, protect and backup your accounts
            and/or Your Content in a manner that will provide appropriate
            security and protection, which might include use of encryption. This
            includes your obligation under this Agreement to record and securely
            maintain any passwords or backup security phrases (i.e. “seed”
            phrases) that relate to your use of the Offerings. You acknowledge
            that you will not share with us nor any other third party any
            password or backup/seed phrase that relates to your use of the
            Offerings, and that we will not be held responsible if you do share
            any such phrase or password. Log-In Credentials and API
            Authentication: To the extent we provide you with log-in credentials
            and API authentication generated by the Offerings, such log-in
            credentials and API authentication are for your use only and you
            will not sell, transfer or sublicense them to any other entity or
            person, except that you may disclose your password or private key to
            your agents and subcontractors performing work on your behalf.
            <br />
            <br /> Applicability to Offerings: For the avoidance of doubt, the
            terms of this Section are applicable to all Offerings, including
            Grypto.io and any accounts you create through Grypto.io with Third
            Party Offerings, such as decentralized applications, or
            blockchain-based accounts themselves.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;